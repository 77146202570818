import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  IconButton,
  Image,
  Stack,
  Text,
  DrawerHeader,
  useDisclosure,
  Spinner,
  Box
} from '@chakra-ui/react'
import { ArrowIcon, PaperClipIcon } from 'assets/icons'
import { ImagesPng } from 'assets/images'
import { handleOpenModal, usersSelectors } from 'store'
import { chatCredentialsSelectors } from 'store/chat/selectors'
import {
  clearConnectedProfileChat,
  setForceCloseChatPanel,
  endMobileImagePicker,
  hideModalSpinner
} from 'store/chat/slice'
import { MODAL_SECTION } from 'utils/constants'
import { COLORS } from 'utils/styles'

import { StyleMessengerBlock, StyleNumUnread } from './styled'

export const Messenger = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { activeProfile } = useSelector(usersSelectors)
  const {
    numUnread,
    isXmppModalSpinnerHidden,
    isMobileImagePickerState,
    isMobileImageTransferInprogress,
    xmppChatPanelOpened,
    forceCloseChatPanel,
    meXmppIsOnline
  } = useSelector(chatCredentialsSelectors)

  const btnRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let touchStart; let touchEnd

  const [converseRoot, setConverseRoot] = useState()
  const [attachmentBtnDisplay, setAttachmentBtnDisplay] = useState('none')
  const [headerPadding, setHeaderPadding] = useState('47px')

  const handleOpen = () => {
    if (activeProfile?.id && isXmppModalSpinnerHidden) {
      // This is to prevent profile card and chat modal being open at the same time
      document.querySelector('div.leaflet-container').click()

      onOpen()

      const isForceCloseChatPanel = forceCloseChatPanel
      if (isForceCloseChatPanel) {
        dispatch(setForceCloseChatPanel(false))
        const isChatShown = xmppChatPanelOpened
        if (isChatShown) {
          setTimeout(() => {
            // close chat panel after the drawer opening animation is complete
            const event = new CustomEvent('custom-chat-close_chat_panel')
            window.dispatchEvent(event)
          }, 500)
        }
      }
    }
  }

  const closeMessenger = () => {
    if (isMobileImagePickerState && !isMobileImageTransferInprogress) {
      dispatch(endMobileImagePicker())
      dispatch(hideModalSpinner())
    }
    onClose()
    dispatch(clearConnectedProfileChat())
    setTimeout(() => {
      const ZoomCont = document.querySelector('.leaflet-control-zoom')
      const filterData = document.querySelector('.filter-data')
      const markerCountBox = document.querySelector('.marker-count')
      ZoomCont.style.opacity = 1
      ZoomCont.style.pointerEvents = 'auto'
      filterData.style.opacity = 1
      filterData.style.pointerEvents = 'auto'
      markerCountBox.style.opacity = 1
      markerCountBox.style.pointerEvents = 'auto'
    }, 1000)
  }

  const closeChatPanel = () => {
    if (isMobileImagePickerState && !isMobileImageTransferInprogress) {
      dispatch(endMobileImagePicker())
      dispatch(hideModalSpinner())
    }
    const isChatShown = xmppChatPanelOpened
    if (isChatShown) {
      const event = new CustomEvent('custom-chat-close_chat_panel')
      window.dispatchEvent(event)
    } else {
      closeMessenger()
    }
  }

  useEffect(() => {
    dispatch(handleOpenModal({ isOpen, section: MODAL_SECTION.RightSection }))
    navigate('/')
  }, [isOpen])

  useEffect(() => {
    if (isXmppModalSpinnerHidden && meXmppIsOnline) {
      setConverseRoot(<converse-root />)
    } else {
      setConverseRoot(
        <center>
          <Spinner marginTop="20px" size="lg" />
        </center>
      )
    }
  }, [isXmppModalSpinnerHidden])

  setTimeout(() => {
    const existing = document.querySelector('#send-attachment-btn')

    setAttachmentBtnDisplay(existing ? 'inline-flex' : 'none')
    setHeaderPadding(existing ? '90px' : '50px')
  }, 50)

  return (
    <>
      <StyleMessengerBlock>
        <StyleNumUnread>{numUnread > 0 ? numUnread : ''}</StyleNumUnread>
        <Image
          boxSize="32px"
          cursor="pointer"
          objectFit="cover"
          src={ImagesPng.LogoHeader}
          alt="Logo"
          borderRadius="full"
          onClick={handleOpen}
          ref={btnRef}
        />
      </StyleMessengerBlock>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={closeMessenger}
        finalFocusRef={btnRef}
        size="sm"
      >
        <DrawerContent
          borderRadius="10px"
          overflow="hidden"
          marginBlock="60px"
          marginInline="10px"
          sx={{
            width: '95% !important'
          }}
          maxWidth="450px"
        >
          <DrawerHeader
            bg={COLORS.goldGradientLeftToRight}
            px="unset"
            py="5px"
            padding="0"
            height="48px"
            onTouchStart={(e) => {
              touchStart = e?.targetTouches[0].clientX
            }}
            onTouchMove={(e) => {
              touchEnd = e?.targetTouches[0].clientX
            }}
            onTouchEnd={(e) => {
              if (touchStart - touchEnd < 45) {
                onClose()
              }
            }}
          >
            <Stack alignItems="center" justifyContent="space-between" direction="row" height="48px">
              <div />

              <Text
                whiteSpace="nowrap"
                fontSize="24px"
                fontWeight={600}
                paddingLeft={headerPadding}
              >
                Messages
              </Text>

              <Box>
                <IconButton
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  transform="rotate(180deg) scaleX(-1)"
                  outline="none"
                  boxShadow="none"
                  height="31px"
                  width="31px"
                  icon={<PaperClipIcon />}
                  onClick={() => {
                    const sendAttachmentBtn = document.querySelector('#send-attachment-btn')

                    if (sendAttachmentBtn) {
                      sendAttachmentBtn.click()
                    }
                  }}
                  display={attachmentBtnDisplay}
                />
                <IconButton
                  _hover={{ background: COLORS.gold_hover }}
                  background="unset"
                  transform="rotate(180deg)"
                  outline="none"
                  boxShadow="none"
                  height="32.5px"
                  borderRadius="0"
                  width="31px"
                  icon={<ArrowIcon />}
                  onClick={() => closeChatPanel()}
                  css={{
                    ':focus': {
                      outline: 'none',
                      boxShadow: 'none'
                    }
                  }}
                />
              </Box>
            </Stack>
          </DrawerHeader>
          <DrawerBody p="0">
            { converseRoot }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
