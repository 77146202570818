
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react'
import { ImagesPng } from 'assets/images'
import { SingleButton } from 'common/Buttons'
import { LoadingSpinier } from 'common/Loading'
import dayjs from 'dayjs'
import {
  archiveRequest,
  setProfileItemId,
  usersSelectors,
  getBlockedRequest
} from 'store'
import {
  NOTIFICATION_TYPE, PENDING, REJECTED, SUCCEEDED
} from 'utils/constants'
import { statusMarkerColor } from 'utils/helpers'
import { COLORS } from 'utils/styles'
import { v4 as uuid } from 'uuid'

import { StyledFlexContainer, StyledWordsContent } from './styled'

export const RequestContent = ({
  getProfileItemStatus,
  profileItem,
  handleNotificationButton,
  requestId,
  isAcceptLoading,
  isRejectLoading
}) => {
  const { archiveRequestStatus, activeProfile } = useSelector(usersSelectors)
  const dispatch = useDispatch()
  const validDate = dayjs(profileItem?.last_accessed)
  const statusColor = statusMarkerColor(validDate)

  return getProfileItemStatus === REJECTED ? (
    <Box width="100%" height="170px">
      <LoadingSpinier />
    </Box>
  ) : (
    <>
      <Stack
        width="100%"
        position="relative"
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >

        <Box
          backgroundImage={profileItem?.banner || ImagesPng?.DefaultImage}
          backgroundColor={null || 'gray.700'}
          bgPosition="100%"
          bgRepeat="no-repeat"
          bgSize="452px 110px"
          zIndex={-1}
          position="absolute"
          width="100%"
          height="110px"
          padding="20px"
          top="0px"
          left={0}
          data-name="coverPicture"
          sx={{
            '@media (max-width: 470px)': {
              width: '100%'
            }
          }}
        >
          <Box />
        </Box>

        <Grid
          width="100%"
          height="95px"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(6, 1fr)"
        >
          <GridItem rowSpan={2} colSpan={2}>
            <Stack
              alignItems="center"
              direction="row"
              spacing="14px"
              position="relative"
              padding="20px"
              sx={{
                '@media (max-width: 480px)': {
                  left: '10px !important'
                }
              }}
            >
              <Image
                boxSize="80px"
                position="relative"
                objectFit="cover"
                src={profileItem?.profile_picture || ImagesPng.DefaultImage}
                alt="User Image"
                borderRadius="full"
                top="-10px"
              />
            </Stack>
          </GridItem>
          <GridItem colSpan={4}>
            <Flex gap="10px" paddingTop="10px" paddingRight="10px">
              <Spacer
                sx={{
                  '@media (max-width: 480px)': {
                    display: 'none'
                  }
                }}
              />
              <SingleButton
                text="Block"
                isLoading={isRejectLoading && profileItem?.id === requestId}
                click={() => {
                  localStorage.setItem('isBlockedNotification', 'false')
                  dispatch(setProfileItemId(null))
                }}
              />
              <SingleButton
                text="Archive"
                isLoading={archiveRequestStatus === PENDING && profileItem?.id === requestId}
                click={() => {
                  dispatch(archiveRequest({
                    id: localStorage.getItem('friendReqID'),
                    activeID: activeProfile?.id
                  }))
                  dispatch(setProfileItemId(null))
                  // dispatch(getBlockedRequest(activeProfile?.id))
                }}
              />

              <SingleButton
                text="Accept"
                isLoading={isAcceptLoading && profileItem?.id === requestId}
                click={() => {
                  handleNotificationButton({
                    id: localStorage.getItem('id'),
                    type: NOTIFICATION_TYPE.Accept
                  })
                  dispatch(setProfileItemId(null))
                }}
              />
            </Flex>
          </GridItem>

          <GridItem colSpan={4}>
            <Stack direction="column" marginTop="5px" spacing="12px" position="relative">
              <Text
                backgroundColor="white"
                p="5px"
                borderRadius={3}
                data-name="fullName"
                width="95%"
                sx={{
                  '@media (max-width: 480px)': {
                    width: '95%'
                  }
                }}
              >
                {profileItem?.full_name}
              </Text>
            </Stack>
          </GridItem>
          <Box />
        </Grid>
      </Stack>
      <Stack direction="column" spacing="10px" sx={{ marginTop: '30px !important' }}>
        <Text align="center" data-name="quote">
          {/* { quote === null ? '' : quote} */}
          {profileItem?.personal_quote}
        </Text>
      </Stack>

      {/* Connections */}
      <Stack textAlign="center" direction="column" justifyContent="space-between" padding="0 50px">
        <Stack justifyContent="space-between" direction="row" spacing="12px">
          <Text color={COLORS.darkGray}>Connections</Text>
          <Text>
            {profileItem?.active_connections}
          </Text>
        </Stack>
        <Stack justifyContent="space-between" direction="row" spacing="12px">
          <Text color={COLORS.darkGray}>Disconnections</Text>
          <Text>
            {profileItem?.past_connections}
            %
          </Text>
        </Stack>
        <Stack justifyContent="space-between" direction="row" spacing="12px">
          <Text color={COLORS.darkGray}>Status</Text>
          <Text>
            {statusColor}
          </Text>
        </Stack>
      </Stack>

      {/* Affinities */}
      <Stack padding={5}>
        <Text fontSize="20px" fontWeight={700}>
          Affinities
        </Text>
        <StyledFlexContainer>
          {profileItem?.affinities?.map((aff) => (
            <StyledWordsContent key={uuid()}>{aff.title}</StyledWordsContent>
          ))}
        </StyledFlexContainer>
      </Stack>

      {/* Social Media */}
      <Stack padding={5}>
        <Text fontSize="20px" fontWeight={700}>
          Social Media
        </Text>
        <Stack direction="column" marginX={5}>
          <Stack direction="column">
            {profileItem?.social_links?.length > 0 ? (
              <Stack direction="row" spacing="10px">
                <Stack gap="10px" spacing="unset" flexWrap="wrap" direction="row">
                  {profileItem.social_links.map((item) => (
                    <Box
                      key={uuid()}
                      borderRadius="full"
                      bg={COLORS.gold}
                      p="6px 10px"
                    >
                      <Link href={item.link} target="_blank" name={item.name} _cursor="pointer" style={{ textDecoration: 'none', color: 'black' }}>
                        {item.name}
                      </Link>
                    </Box>
                  ))}
                </Stack>
              </Stack>
            ) : (
              <Text as="span" color={COLORS.gray}>No data available</Text>
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
